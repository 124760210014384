import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import IconBoxGrid from "components/IconBoxGrid/IconBoxGrid";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
      <Container>
        <Row>
          <Col>
            <h1>404: NOT FOUND</h1>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="7">
            <p>You just hit a route that doesn&#39;t exist on this website.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="bluedark">
      <Container>
        <Row>
          <Col className="d-flex align-items-center flex-column">
            <p className="mini-lead">What We Offer</p>
            <h3 className="text-center">Class Information</h3>
            <p className="lead">
              Select one of the training branches below to view details,
              pricing, and class schedules.
            </p>
            <IconBoxGrid />
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
);

export default NotFoundPage;
